<template>
  <div class="outermost">
    <layout
      class="layout"
      :hd="false"
      :ft="true"
    >
      <div slot="rt"></div>
      <div slot="le"></div>
      <div class="personal-background">
        <div
          class="personal-content"
          @click="jumpPage('/me/info')"
        >
          <img
            ref="avatarImg"
            v-if="userInfo"
            :src="userInfo.avatar"
            alt=""
          />
          <img
            v-else
            src="../../assets/img/me/default.png"
            alt=""
          />
          <div class="personal-content-information">
            <div class="personal-content-information-top">
              {{ newname }}
              <div></div>
            </div>
            <div class="personal-content-information-bottom">
              点击更新个人信息
            </div>
          </div>
        </div>
      </div>
      <van-cell-group
        inset
        class="options-list"
      >
        <van-cell
          class="options-item"
          title="军人认证"
          @click="jumpPage('/me/approve')"
        >
          <img
            src="../../assets/img/me/authentication-2x.png"
            alt=""
            slot="icon"
          />
          <template #right-icon>
            <van-icon
              name="arrow"
              class="icon"
            />
          </template>
        </van-cell>
        <van-cell
          class="options-item elder"
          title="长辈模式"
        >
          <img
            src="../../assets/img/me/set-up-2x.png"
            alt=""
            slot="icon"
          />
          <template #right-icon>
            <van-switch
              v-model="checked"
              size="24"
            />
          </template>
        </van-cell>
      </van-cell-group>
      <van-cell-group
        inset
        class="options-list"
      >
        <van-cell
          class="options-item news"
          title="我的消息"
          @click="jumpPage('/me/my-news')"
        >
          <img
            src="../../assets/img/me/news-2x.png"
            alt=""
            slot="icon"
          />
          <template #right-icon>
            <div
              class="dot"
              v-if="$store.state.user.userNews != 0"
            ></div>
            <van-icon
              name="arrow"
              class="icon"
            />
          </template>
        </van-cell>
              <van-cell
          class="options-item"
          title="智能客服"
          @click="jumpPage('/me/my-service')"
        >
         <van-icon
            class="img-icon"
            size="24"
            name="friends-o"
            slot="icon"
          />
          <template #right-icon>
            <van-icon
              name="arrow"
              class="icon"
            />
          </template>
        </van-cell>
        <van-cell
          class="options-item"
          title="文章留言"
          @click="jumpPage('/me/my-leave')"
        >
          <img
            src="../../assets/img/me/leave.png"
            alt=""
            slot="icon"
          />
          <template #right-icon>
            <van-icon
              name="arrow"
              class="icon"
            />
          </template>
        </van-cell>
        <van-cell
          class="options-item"
          title="我的点赞"
          @click="jumpPage('/me/like')"
        >
          <img
            src="../../assets/img/me/like.png"
            alt=""
            slot="icon"
          />
          <template #right-icon>
            <van-icon
              name="arrow"
              class="icon"
            />
          </template>
        </van-cell>
        <van-cell
          class="options-item"
          title="我的分享"
        >
          <img
            src="../../assets/img/me/Share.png"
            alt=""
            slot="icon"
          />
          <template #right-icon>
            <van-icon
              name="arrow"
              class="icon"
            />
          </template>
        </van-cell>
        <van-cell
          class="options-item"
          title="咨询问题"
          @click="jumpPage('/me/my-issue')"
        >
          <img
            src="../../assets/img/me/news-2x.png"
            alt=""
            slot="icon"
          />
          <template #right-icon>
            <van-icon
              name="arrow"
              class="icon"
            />
          </template>
        </van-cell>
        <van-cell
          class="options-item"
          title="我的报名"
          @click="jumpPage('/me/my-signUp')"
        >
          <van-icon
            class="img-icon"
            size="24"
            name="friends-o"
            slot="icon"
          />
          <!-- <img src="../../assets/img/me/set-up-2x.png" alt="" slot="icon" /> -->
          <template #right-icon>
            <van-icon
              name="arrow"
              class="icon"
            />
          </template>
        </van-cell>
      </van-cell-group>
    </layout>
  </div>
</template>

<script>
import Layout from '@/components/layout.vue'

import { mapActions } from 'vuex'
// import { listByUser } from '@/api/me'
import { SoldiersCertification } from '@/api/me'
import { getItem, setItem } from '@/utils/auth'
export default {
  components: {
    Layout
  },
  data () {
    return {
      news: 0,
      checked: false,
      approve: false
    }
  },
  mounted () {
    // this.listByUser()
  },
  computed: {
    newname () {
      console.log(this.$store.state.user.userInfo)
      if (getItem('userInfo')) {
        if (!this.approve) {
          if (getItem('userInfo').realname.length == 2) {
            return `${getItem('userInfo').realname.substr(0, 1)}*`
          } else if (getItem('userInfo').realname.length == 3) {
            return `${getItem('userInfo').realname.substr(0, 1)}**`
          } else if (getItem('userInfo').realname.length == 4) {
            return `${getItem('userInfo').realname.substr(0, 1)}${getItem('userInfo').realname.substr(1, 1)}**`
          }
        } else {
          return getItem('userInfo').realname
        }
      }
    },
    userInfo () {
      return getItem('userInfo')
    }
  },

  watch: {
    checked: {
      handler (val, oldVal) {
        this.$store.commit('user/alterElder', val)
      }
    },
    userInfo: {
      handler (val, oldVal) {
        SoldiersCertification({
          userId: getItem('userInfo').id
        }).then((res) => {
          this.approve = res.result
        })
      },
      immediate: true
    }
  },
  created () {
    this.checked = this.$store.state.user.elderModel
    this['user/getlistByUser']()
  },
  methods: {
    // async listByUser() {
    //   const result = await listByUser()
    //   this.news = result.result.sysMsgTotal
    // },

    jumpPage (url) {
      this.$router.push(url)
    },
    ...mapActions(['user/getlistByUser']) // 引入方法
  }
}
</script>

<style lang="less" scoped>
.outermost {
  .layout /deep/ .main {
    padding-top: 0 !important;
  }
  position: absolute;
  top: 0;
  bottom: -80px;
  overflow-y: auto;
  right: 0;
  left: 0;

  .personal-background {
    height: 150px;
    background: url('../../assets/img/me/background-2x.png') no-repeat;
    background-size: 100% 100%;
    overflow: hidden;
    .personal-content {
      display: flex;
      align-items: center;
      margin-left: 16px;
      margin-top: 61px;
      img {
        width: 68px;
        height: 68px;
        border-radius: 68px;
      }
      .personal-content-information {
        margin-left: 26px;
        .personal-content-information-top {
          font-size: 20px;
          color: #ffffff;
          display: flex;
          align-items: center;
          div {
            width: 10px;
            height: 10px;
            border-top: 2px solid #fff;
            border-right: 2px solid #fff;
            transform: rotate(45deg);
          }
        }
        .personal-content-information-bottom {
          font-size: 14px;
          color: #c4dcab;
          margin-top: 5px;
        }
      }
    }
  }

  .options-list {
    margin: 20px 14px 0 16px;
    .options-item {
      padding: 15px 10px;
      font-size: 16px;
      font-family: PingFang SC, PingFang SC-Regular;
      font-weight: 400;
      text-align: LEFT;
      color: #000000;
      letter-spacing: 0px;
      & /deep/ .van-switch--on {
        background-color: #10955b;
      }
      .img-icon {
        margin-right: 18px;
        width: 24px;
        height: 24px;
      }
      img {
        width: 24px;
        height: 24px;
        margin-right: 18px;
      }
      .icon {
        line-height: 24px;
      }
      &::after {
        border: 1px solid #eeeeee;
      }
    }
  }
  .news {
    display: flex;
    align-items: center;
  }
  .dot {
    width: 8px;
    height: 8px;
    background-color: red;
    border-radius: 10px;
  }
}
</style>