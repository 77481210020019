<template>
 <div>
 <myNormal v-if="!$store.state.user.elderModel"/>
 <myElder v-else/>
 </div>
</template>

<script>
import myNormal from './my-normal.vue'
import myElder from './my-elder.vue'
 export default {
   name: '',
   components: {
myNormal,
myElder
   },
   data () {
     return {

     }
   },
   computed: {

   },
   created() {

   },
   mounted() {

   },
   methods: {

   }
 }
</script>

<style  scoped>

 
</style>
